import * as React from 'react'
import BaseLayoutTool from '../../components/base-layout-tool'

const ShuffleRowsPage = () => {
    return (
        <BaseLayoutTool 
        pageTitle="Shuffle Spreadsheet Rows | App " 
        pageDescription="Shuffles the rows in the spreadsheet in a pseudorandom fashion using this TNNL tool. Works with csv and Excel files."
        toolLink="https://dfshuffle-ne2vtntboq-nw.a.run.app"
        />
    )
}

export default ShuffleRowsPage